import React, { useState, useRef } from 'react'
import axios from 'axios'
import { RxCrossCircled } from 'react-icons/rx'

function FloorPlan({ formData, setFormData }) {
  const [isSelected, setIsSelected] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [message, setMessage] = useState('')
  const [floorPlanName, setFloorPlanName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  // Create a ref for the file input
  const fileInputRef = useRef(null)

  const floorPlanChange = (e) => {
    if (e.target.files) {
      setIsSelected(e.target.files[0])
    } else {
      setFloorPlanName(e.target.value)
    }
  }

  const handleSubmit = async (e) => {
    setIsLoading(true)
    if (isSelected && floorPlanName) {
      e.preventDefault()
      const formDataFloor = new FormData()

      const selectedImageName = `${Date.now()}.webp`
      formDataFloor.append('coverImage', isSelected, selectedImageName)

      const token = localStorage.getItem('token')

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/file-upload/image-upload`, formDataFloor, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setUploadProgress(percentCompleted)
          }
        })

        if (response.status === 200) {
          setMessage('Floor Added Successfully!')
          setFormData({
            ...formData,
            floorPlan: [{ image: selectedImageName, name: floorPlanName }, ...formData.floorPlan]
          })
          setUploadProgress(0)
          setFloorPlanName('')
          setIsSelected(null)

          // Use the ref to clear the file input
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
        }
      } catch (error) {
        console.error('Error uploading floor plan:', error)
      } finally {
        setUploading(false)
        setIsLoading(false)
      }
    }
  }

  const removeImage = (imageName) => {
    const updatedFloorPlans = formData.floorPlan.filter((data) => data.image !== imageName)
    setFormData({ ...formData, floorPlan: updatedFloorPlans })
  }
  const ImageFolderRoot = `${process.env.REACT_APP_API_URL}/images/`

  return (
    <div>
      <h3 className="text-blue-900 font-semibold text-2xl text-center block">Floor Plan</h3>
      <div className="w-full my-5">
        <label htmlFor="" className="block">
          Floor Plan
        </label>
        <input
          type="text"
          name="floorPlanName"
          className="block w-full form-input rounded-md border-gray-500 my-2"
          autoComplete="off"
          placeholder="Enter floor plan name"
          onChange={floorPlanChange}
          value={floorPlanName}
        />
        <input
          type="file"
          name="floorImage"
          className="block w-full form-input rounded-md border-gray-500 my-2"
          autoComplete="off"
          accept="image/*"
          onChange={floorPlanChange}
          ref={fileInputRef} // Attach the ref to the file input
        />
        {isLoading ? (
          <div
            value="Add Floor Plan"
            className="block opacity-60 w-full form-input btn rounded-md border-gray-500 my-2 cursor-pointer text-white bg-green-600 text-center"
            autoComplete="off"
          >
            Adding...
          </div>
        ) : (
          <div
            value="Add Floor Plan"
            className="block w-full form-input btn rounded-md border-gray-500 my-2 cursor-pointer text-white bg-green-600 text-center"
            autoComplete="off"
            onClick={handleSubmit}
          >
            Add Floor
          </div>
        )}

        {message && <p className="text-green-500 text-center">{message}</p>}
        {uploading && (
          <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
            <div
              className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
              style={{ width: uploadProgress + '%' }}
            >
              {' '}
              {uploading && <p>Uploading... {uploadProgress}%</p>}
            </div>
          </div>
        )}
      </div>
      {formData.floorPlan.length !== 0 && (
        <div className="flex flex-warp justify-start gap-5">
          {formData.floorPlan.map((data) => (
            <div key={data.name} className="grid relative">
              <img src={ImageFolderRoot + '/' + data.image} alt={data.name} className="w-[50px] h-[50px]" />
              <span className="text-sm text-center">{data.name}</span>
              <div
                onClick={() => removeImage(data.image)}
                className="cursor-pointer bg-red-600 rounded-full text-white w-5 h-5 flex justify-center items-center absolute right-0 top-0"
              >
                <RxCrossCircled />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default FloorPlan
