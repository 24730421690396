import { useEffect, useRef, useState } from 'react'
import createAxiosInstance from '../action/apiConfig'
import { MdDelete } from 'react-icons/md'
function Gallery() {
  const [images, setImages] = useState([])
  const [selectedImages, setselectedImages] = useState([])
  const [selectedChar, setselectedChar] = useState('')
  const [chars, setchars] = useState([])
  const [filter, setfilter] = useState(true)
  const [loading, setLoading] = useState(true)
  const [UploadImage, setUploadImage] = useState(null)
  const [copySuccess, setCopySuccess] = useState('')

  const inputRef = useRef()

  function copyToClipboard(code) {
    navigator.clipboard.writeText(code)
    setCopySuccess('Copied!')
    const timer = setTimeout(() => {
      return setCopySuccess('')
    }, 1000)
  }
  const fetchImages = async () => {
    try {
      const response = await createAxiosInstance().get(`/city/images/all`)
      setImages(() => response.data.files.sort())

      const char = new Set()
      response.data.files.map((m) => {
        char.add(m.slice(0, 1).toUpperCase())
      })
      let arr = Array.from(char).sort()
      arr.push('Display All')
      setchars(arr)
      //   console.log(response.data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchImages()
  }, [])

  function handleChange(e) {
    setselectedChar(e.target.value)
    if (e.target.value === 'Display All') {
      setselectedImages(
        images.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase())
        })
      )
    } else {
      const arr = images.filter((m) => m.slice(0, 1).toUpperCase() === e.target.value)
      setselectedImages(arr)
    }
  }
  async function refresh() {
    const response = await createAxiosInstance().get(`/city/images/all`)
    setImages(() => response.data.files)
    const char = new Set()
    response.data.files.map((m) => {
      char.add(m.slice(0, 1).toUpperCase())
    })
    let arr2 = Array.from(char).sort()
    arr2.push('Display All')
    setchars(arr2)
    if (selectedChar === 'Display All') {
      setselectedImages(
        response.data.files.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase())
        })
      )
    } else {
      const arr = response.data.files.filter((m) => m.slice(0, 1).toUpperCase() === selectedChar)
      setselectedImages(arr)
    }
  }
  async function handleDelete(name) {
    try {
      const response = await createAxiosInstance().post(`/city/delete-image/${name}`)
      refresh()
      console.log(response)
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoading(false)
    }
  }
  async function handleUpload() {
    try {
      const formData = new FormData()
      formData.append('upload', UploadImage)
      const response = await createAxiosInstance().post('city/image-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      refresh()
      setUploadImage(null)
      inputRef.current.value = ''
      console.log(response)
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  return (
    <div>
      <h1>Image gallery</h1>
      <label className="pr-1" htmlFor="slug">
        Choose a Letter:
      </label>
      <select className="ml-2 w-48" name="slug" id="slug" onChange={handleChange}>
        <option value="Select Letter">Select Letter</option>
        {chars.map((m) => (
          <option value={m} key={m}>
            {m}
          </option>
        ))}
      </select>
      <label htmlFor="cityImage" className="ml-[200px] font-bold">
        Image <span className="text-red-600">*</span>{' '}
      </label>
      <input
        ref={inputRef}
        type="file"
        name="cityImage"
        required
        id="cityImage"
        accept="image/*"
        onChange={(e) => {
          setUploadImage(e.target.files[0])
        }}
      />
      <button className="bg-black text-white p-1 px-5 rounded-md ml-20" onClick={handleUpload}>
        Upload
      </button>
      <div className="flex flex-wrap gap-4 mt-5">
        {selectedImages.map((m, i) => (
          <div className="w-[290px] relative" key={i}>
            <button
              onClick={() => handleDelete(m)}
              className="absolute top-0 left-0 hover:bg-white bg-black rounded-md"
            >
              <MdDelete className="text-red-500 w-7 h-7" />
            </button>
            <img src={`${process.env.REACT_APP_API_URL}/city/serveimage?imagekey=${m}`} alt="" />
            <h2 className="text-sm">{m}</h2>
            <button
              className="absolute top-0 right-0 hover:cursor-Pointer lg:hover:bg-gray-200 lg:hover:text-gray-900 text-gray-100 text-sm bg-gray-700 rounded-md p-1 leading-3 border lg:hover:border-blue-800"
              onClick={() =>
                copyToClipboard(
                  `<CustomCityImage
                src="${process.env.REACT_APP_API_URL}/city/serveimage?imagekey=${m}"
                alt="custom Image"
                width="1000px"
                height="700px"
              />`
                )
              }
              id="copy"
              aria-label="copy"
            >
              <span className="hidden md:inline-flex md:mr-1">Code</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4 inline"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
      <h3 className="fixed w-full bottom-0 left-0 bg-green-500">{copySuccess}</h3>
    </div>
  )
}
export default Gallery
