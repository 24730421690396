import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

function TextEditor2({ data, setBlog, blog }) {
  const [words, setwords] = useState(0)
  const [chars, setchars] = useState(0)
  return (
    <div className="App">
      {data ? (
        <>
          <CKEditor
            editor={Editor}
            data={data}
            config={{
              mediaEmbed: {
                previewsInData: true
              },
              wordCount: {
                onUpdate: (stats) => {
                  // Prints the current content statistics.
                  // console.log(`Characters: ${stats.characters}\nWords: ${stats.words}`)
                  setwords(stats.words)
                  setchars(stats.characters)
                }
              },
              simpleUpload: {
                // The URL that the images are uploaded to.
                uploadUrl: 'https://server-new.preconstruction.info/blog/image-upload/'

                // Enable the XMLHttpRequest.withCredentials property.

                // Headers sent along with the XMLHttpRequest to the upload server.
              }
            }}
            onChange={(event, editor) => {
              const newData = editor.getData()
              setBlog({ ...blog, description: newData })
            }}
          />
          <div className="p-3 mt-3 border border-l-[6px] border-l-violet-500 border-gray-300 shadow-md rounded-tr-md rounded-br-md">
            <h2 className="text-xl font-bold mb-1">Text statistics</h2>
            <div className="">Words: {words}</div>
            <div className="">Characters: {chars}</div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default TextEditor2
