import axios from 'axios'
// Define your API base URL
const BASE_URL = process.env.REACT_APP_API_URL // Replace with your actual API URL

// Function to create an instance of Axios with common headers
const createAxiosInstance = () => {
  // Check if the token exists in localStorage
  const token = localStorage.getItem('token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const headers = {
    'Content-Type': 'application/json', // Set the appropriate content type
    Authorization: `Bearer ${token}` // Add the token as a bearer token
  }

  return axios.create({
    baseURL: BASE_URL,
    headers
  })
}

// Export the Axios instance
export default createAxiosInstance
