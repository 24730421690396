import React, { useEffect, useState } from 'react'
import TextEditor2 from '../components/TextEditor/TextEditor2'
import createAxiosInstance from '../action/apiConfig'
import { useNavigate, useParams } from 'react-router-dom'

const categoryList = [
  'Trending',
  'Canada Real Estate Market',
  'Miami Real Estate Market',
  'Real Estate Investment',
  'Upgrade',
  'Finance',
  'Blog'
]

export default function EditBlog() {
  const [locked, setlocked] = useState(true)
  const params = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [blog, setBlog] = useState({
    title: '',
    description: '',
    slug: '',
    postedBy: '',
    likes: [],
    comments: [],
    categories: '',
    coverImage: null,
    tags: [],
    seoDes: '',
    seoKeywords: ''
  })
  console.log(data)
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const { data } = await createAxiosInstance().get(`/blog/single/${params.id}`)
        setData(data)
        setBlog({
          title: data.title,
          description: data.description,
          slug: data.slug,
          postedBy: data.postedBy,
          likes: [],
          comments: [],
          categories: data.categories,
          coverImage: null,
          tags: data.tags,
          seoDes: data.seoDes,
          seoKeywords: data.seoKeywords
        })
        // console.log(response.data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching blog data:', error)
        setLoading(false)
      }
    }

    fetchBlog()
  }, [])

  // console.log(params)

  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  async function updateBlog() {
    setLoading(true)

    const formData = new FormData()
    formData.append('title', blog.title.trim())
    formData.append('description', blog?.description.trim())
    formData.append('slug', blog.slug.trim())
    formData.append('postedBy', blog.postedBy)
    formData.append('categories', blog.categories)
    blog.coverImage && formData.append('coverImage', blog.coverImage)
    formData.append('tags', blog.tags)
    formData.append('seoDes', blog.seoDes.trim())
    blog.seoKeywords && formData.append('seoKeywords', blog.seoKeywords)

    const response = await createAxiosInstance().patch(`/blog/update/${data._id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if (response.data?.ok) {
      console.log(data)
      setSuccessMessage(response.data.message)
      setLoading(false)
      setTimeout(() => {
        setSuccessMessage('')
      }, 4000)
      // setBlog({
      //   title: '',
      //   description: '',
      //   slug: '',
      //   postedBy: '',
      //   likes: [],
      //   comments: [],
      //   categories: '',
      //   coverImage: null,
      //   tags: [],
      //   seoDes: '',
      //   seoKeywords: ''
      // })
      // navigate('/blog-list')
    } else {
      console.log('Something went wrong')
      setErrorMessage('Error: No response data received from the server.')
      setLoading(false)
    }
  }
  //creating slug
  function createSlug(str) {
    return str
      .toLowerCase()
      .replace(/ /g, '-') // Replace spaces with dashes
      .replace(/[^\w-]+/g, '') // Remove non-word characters except dashes
  }

  // // Event handler for the title input
  // const handleTitleChange = (e) => {
  //   const titleValue = e.target.value
  //   const slugValue = createSlug(titleValue) // Create slug from the title
  //   setBlog({
  //     ...blog,
  //     title: titleValue,
  //     slug: slugValue
  //   })
  // }

  const handleInputChange = (event, isTitle) => {
    const { name } = event.target
    if (isTitle) {
      const titleValue = event.target.value
      const slugValue = createSlug(titleValue) // Create slug from the title
      setBlog(
        locked
          ? {
              ...blog,
              title: titleValue,
              slug: slugValue
            }
          : {
              ...blog,
              title: titleValue
            }
      )
    } else {
      const newValue = event.target.value
      console.log('Updated Blog State :', { ...blog, [name]: newValue })
      setBlog({ ...blog, [name]: newValue })
    }
  }

  return (
    <div className="w-full  mb-[60px]">
      <h2 className="text-3xl font-bold mb-8">Edit Blog</h2>
      <form
        action=""
        className="flex flex-col gap-4"
        onSubmit={(e) => {
          e.preventDefault()
          updateBlog()
        }}
      >
        <label htmlFor="title" className="font-bold">
          Title <span className="text-red-600">*</span>{' '}
        </label>
        <input
          type="text"
          placeholder="Enter Title"
          onChange={(e) => handleInputChange(e, true)}
          value={blog.title} // controlled component, value is set to state
          required
        />
        <label htmlFor="slug" className="flex font-bold">
          Url <span className="text-red-600 mr-1">*</span>
          <span>
            {locked ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 hover:cursor-pointer"
                onClick={() => setlocked((st) => !st)}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 hover:cursor-pointer"
                onClick={() => setlocked((st) => !st)}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
            )}
          </span>
        </label>
        <input
          id="slug"
          type="text"
          name="slug"
          placeholder="Enter url"
          onChange={(e) => {
            setBlog({ ...blog, [e.target.name]: e.target.value.toLowerCase() })
          }}
          value={blog.slug} // controlled component, value is set to state
          required
          disabled={locked}
          className={locked ? 'hover:cursor-not-allowed' : ''}
        />
        <label htmlFor="postedBy" className="font-bold">
          Author Name <span className="text-red-600">*</span>{' '}
        </label>
        <input
          type="text"
          placeholder="Author Name"
          value={blog.postedBy}
          onChange={(e) => {
            setBlog({ ...blog, postedBy: e.target.value })
          }}
        />
        <label htmlFor="categories" className="font-bold">
          Categories <span className="text-red-600">*</span>{' '}
        </label>
        <select
          name="categories"
          id="categories"
          value={blog.categories}
          onChange={(e) => {
            setBlog({ ...blog, categories: e.target.value })
          }}
          required
        >
          <option value="">Select Category</option>
          {categoryList.map((category, i) => (
            <option key={category} value={category}>
              {' '}
              {category}{' '}
            </option>
          ))}
        </select>

        <label htmlFor="tags" className="font-bold">
          Tags<span className="text-red-600">* (Comma Seperated)</span>
        </label>
        <input
          type="text"
          placeholder="tag1, tag2, tag3"
          name="tags"
          id="tags"
          value={blog.tags}
          onChange={(e) => {
            setBlog({ ...blog, tags: e.target.value })
          }}
          required
        />

        <label htmlFor="description" className="font-bold">
          Description <span className="text-red-600">*</span>{' '}
        </label>
        <TextEditor2 setBlog={setBlog} blog={blog} data={blog?.description} />
        <label htmlFor="coverImage" className="font-bold">
          Image <span className="text-red-600">*</span>{' '}
        </label>
        <input
          type="file"
          name="coverImage"
          id="coverImage"
          accept="image/*"
          onChange={(e) => {
            setBlog({ ...blog, coverImage: e.target.files[0] })
          }}
        />

        <h3 className="text-blue-900 font-semibold text-2xl text-center block">Product SEO</h3>

        <div className="w-full my-5">
          <label htmlFor="" className="block">
            SEO Meta Description <span className="text-red-600">*</span>
          </label>
          <textarea
            type="text"
            name="seoDes"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter SEO meta description"
            accept="image/*"
            multiple={true}
            onChange={handleInputChange}
            value={blog.seoDes}
            required
          />
        </div>
        <div className="w-full my-5">
          <label htmlFor="" className="block">
            SEO Meta Keywords <span className="text-red-600">*</span>
            <span className="text-green-600">(Comma Separated)</span>
          </label>
          <textarea
            type="text"
            name="seoKeywords"
            className="block w-full form-input rounded-md border-gray-500"
            autoComplete="off"
            placeholder="Enter SEO meta keywords"
            multiple={true}
            onChange={handleInputChange}
            value={blog.seoKeywords}
          />
        </div>

        <button className="bg-blue-500 p-2 text-white font-bold" type="submit">
          {loading ? 'Updating...' : 'Update Blog'}
        </button>
      </form>
      {successMessage && <div className="text-green-600">{successMessage}</div>}
      {errorMessage && <div className="text-red-600">{errorMessage}</div>}
    </div>
  )
}
