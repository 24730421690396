import React, { useEffect, useState } from 'react'
import { BsDatabaseFillCheck } from 'react-icons/bs'
import { BiSolidPackage } from 'react-icons/bi'
import createAxiosInstance from '../action/apiConfig'
function Dashboard() {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  useEffect(() => {
    const result = createAxiosInstance()
    result.get('/admin/count').then((res) => {
      // console.log(res.data)
      setData(res.data)
      setIsLoading(false)
    })
  }, [])
  return (
    <div className="w-full h-full">
      <div className="grid grid-cols-1 gap-10 md:gap-10 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 lg:gap-10">
        <div className="w-full h-[200px] bg-blue-950 rounded-2xl p-4 text-center grid justify-center align-middle shadow-xl hover:translate-y-3 transition-all cursor-pointer">
          <BsDatabaseFillCheck size={70} className="m-auto pb-3 text-orange-500" />
          <h1 className="text-3xl text-white uppercase font-extrabold">Leads</h1>
          <p className="text-2xl text-white">{isLoading ? 'Loading...' : data.data}</p>
        </div>
        <div className="w-full h-[200px] bg-blue-950 rounded-2xl p-4 text-center grid justify-center align-middle shadow-xl  hover:translate-y-3 transition-all cursor-pointer">
          <BiSolidPackage size={70} className="m-auto pb-3 text-orange-500" />
          <h1 className="text-3xl text-white uppercase font-extrabold">Products</h1>
          <p className="text-2xl text-white">{isLoading ? 'Loading...' : data.product}</p>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
