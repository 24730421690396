import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Layout from './components/Shared/Layout'
import Dashboard from './pages/Dashboard'
import AddProduct from './pages/AddProduct'
import EditProduct from './pages/EditProduct'
import AddBlog from './pages/AddBlog'
import EditBlog from './pages/EditBlog'
import BlogList from './pages/BlogList'
import LocationAdd from './components/LocationAdd/LocationAdd'
import Login from './pages/Login'
import FileUpload from './components/UploadImage/UploadImage'
import LogOut from './action/logout'
import createAxiosInstance from './action/apiConfig'
import ProductTable from './pages/ProductTable'
import DataList from './pages/DataList'
import ChangeInfo from './components/ChangeInfo/ChangeInfo'
import UpdateCityCountry from './pages/UpdateCityCountry'
import Gallery from './pages/Gallery'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token'))
  // Check for a token in localStorage on app load
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      const request = createAxiosInstance()
      request
        .post('/auth/isAuthenticated')
        .then((response) => {
          setIsAuthenticated(true)
          // console.log(response)
        })
        .catch((error) => {
          setIsAuthenticated(false)
          LogOut()
          console.log(error)
        })
    } else {
      setIsAuthenticated(false)
    }
  }, [])
  // Check for authentication on page load and route change
  useEffect(() => {
    if (!isAuthenticated && window.location.pathname !== '/login') {
      LogOut()
    }
  }, [isAuthenticated])

  return (
    <Router>
      <Routes>
        <Route path="/" element={isAuthenticated ? <Layout /> : <Navigate to="/login" />}>
          <Route index element={<Dashboard />} />
          <Route path="add-product" element={<AddProduct />} />
          <Route path="edit-product/:id" element={<EditProduct />} />
          <Route path="location" element={<LocationAdd />} />
          <Route path="upload" element={<FileUpload />} />
          <Route path="products-list" element={<ProductTable />} />
          <Route path="data-list" element={<DataList />} />
          <Route path="update-info" element={<ChangeInfo />} />
          <Route path="add-blog" element={<AddBlog />} />
          <Route path="blog-list" element={<BlogList />} />
          <Route path="edit-blog/:id" element={<EditBlog />} />
          <Route path="update-city-country" element={<UpdateCityCountry />} />
          <Route path="gallery" element={<Gallery />} />
        </Route>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/" /> : <Login setIsAuthenticated={setIsAuthenticated} />}
        />
      </Routes>
    </Router>
  )
}

export default App
