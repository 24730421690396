import React, { useState, useRef, useEffect } from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY // Replace with your Google Maps API key
const libraries = ['places'] //
const LocationAdd = ({ formData, setFormData }) => {
  const [map, setMap] = useState(null)
  const [searchQuery, setSearchQuery] = useState(formData.location || '')
  const [markers, setMarkers] = useState(
    formData.coordinates.coordinates[0]
      ? [
          {
            position: {
              lng: formData.coordinates?.coordinates[0],
              lat: formData.coordinates?.coordinates[1]
            },
            title: formData.location
          }
        ]
      : []
  )
  const [center, setCenter] = useState({
    lng: formData.coordinates?.coordinates[0] || -74.006,
    lat: formData.coordinates?.coordinates[1] || 40.7128
  }) // Default center

  const inputRef = useRef(null)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey,
    libraries
  })

  useEffect(() => {
    if (map && inputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current)
      autocomplete.bindTo('bounds', map)

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()

        if (!place.geometry) {
          alert('Location not found')
          return
        }

        const location = place.geometry.location
        setCenter({ lat: location.lat(), lng: location.lng() })

        const newMarkers = [
          {
            position: { lat: location.lat(), lng: location.lng() },
            title: place.name
          }
        ]

        setMarkers(newMarkers)
        setSearchQuery(place.formatted_address) // Update the input with the selected location's name
        setFormData({
          ...formData,
          location: place.formatted_address,
          coordinates: { coordinates: [location.lng(), location.lat()] }
        })
        // console.log(place)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  const handleMapLoad = (map) => {
    setMap(map)
  }

  const handleMapClick = (event) => {
    if (!map) return

    const geocoder = new window.google.maps.Geocoder()
    const latLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const locationName = results[0].formatted_address
        setSearchQuery(locationName)

        const newMarkers = [
          {
            position: { lat: latLng.lat, lng: latLng.lng },
            title: locationName
          }
        ]
        setMarkers(newMarkers)
        setCenter({ lat: latLng.lat, lng: latLng.lng })
        setFormData({
          ...formData,
          location: locationName,
          coordinates: { coordinates: [latLng.lng, latLng.lat] }
        })
      } else {
        alert('Location not found')
      }
    })
  }

  const searchLocation = () => {
    if (!map) return

    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ address: searchQuery }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const location = results[0].geometry.location
        setCenter({ lat: location.lat(), lng: location.lng() })

        const newMarkers = [
          {
            position: { lat: location.lat(), lng: location.lng() },
            title: searchQuery
          }
        ]
        setMarkers(newMarkers)
        setFormData({
          ...formData,
          coordinates: { coordinates: [location.lng(), location.lat()] }
        })
      } else {
        alert('Location not found')
      }
    })
  }

  if (!isLoaded) {
    return <div>Loading Map...</div>
  }

  return (
    // <LoadScript googleMapsApiKey={googleMapApiKey} libraries={libraries}>
    <div>
      <div className="grid grid-cols-[1fr_auto] gap-2 mb-2">
        <input
          type="text"
          ref={inputRef}
          placeholder="Enter a location..."
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value)
            setFormData({ ...formData, location: e.target.value })
          }}
          className="form-input rounded-md"
        />
        <div onClick={searchLocation} className="btn p-2 px-4 text-white rounded-md bg-slate-700 cursor-pointer">
          Search
        </div>
      </div>
      <div style={{ height: '500px', width: '100%' }}>
        <GoogleMap
          mapContainerStyle={{
            height: '100%',
            width: '100%'
          }}
          zoom={12}
          center={center}
          onLoad={handleMapLoad}
          onClick={handleMapClick}
        >
          {markers.map((marker, index) => (
            <Marker key={index} position={marker.position} title={marker.title} />
          ))}
        </GoogleMap>
      </div>
    </div>
    // </LoadScript>
  )
}

export default LocationAdd
